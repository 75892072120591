var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-sheet",
    {
      staticClass: "mx-auto mb-6",
      attrs: { elevation: "8", "max-width": "800" }
    },
    [
      _c(
        "v-slide-group",
        {
          staticClass: "pa-1",
          attrs: { mandatory: "", "show-arrows": "", "center-active": "" },
          model: {
            value: _vm.model,
            callback: function($$v) {
              _vm.model = $$v
            },
            expression: "model"
          }
        },
        _vm._l(_vm.items, function(ref, index) {
          var primary_contact = ref.primary_contact
          return _c("v-slide-item", {
            key: index,
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var active = ref.active
                    var toggle = ref.toggle
                    return [
                      _c(
                        "v-card",
                        {
                          staticClass: "ma-2",
                          attrs: {
                            color: active
                              ? "accent lighten-2"
                              : "grey lighten-4",
                            height: "150",
                            width: "330"
                          },
                          on: { click: toggle }
                        },
                        [
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "fill-height",
                                  attrs: { align: "center", justify: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0 my-0",
                                      attrs: { cols: "10" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "d-flex",
                                          class: { "white--text": active }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-2",
                                              class: { "white--text": active }
                                            },
                                            [_vm._v("mdi-map-marker")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "d-flex flex-column"
                                            },
                                            [
                                              _c("h4", [
                                                _vm._v(
                                                  _vm._s(primary_contact.name)
                                                )
                                              ]),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-weight-regular"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        primary_contact.address
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-weight-light"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      primary_contact.city
                                                    ) +
                                                      ", " +
                                                      _vm._s(
                                                        primary_contact.state
                                                      )
                                                  )
                                                ]
                                              ),
                                              _c("v-divider", {
                                                staticClass: "my-1",
                                                class: {
                                                  white: active
                                                }
                                              }),
                                              _c(
                                                "div",
                                                { staticClass: "d-flex" },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "mr-1",
                                                      class: {
                                                        "white--text": active
                                                      },
                                                      attrs: { small: "" }
                                                    },
                                                    [_vm._v("mdi-phone")]
                                                  ),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        primary_contact.phone
                                                      )
                                                    )
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "d-flex" },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "mr-1",
                                                      class: {
                                                        "white--text": active
                                                      }
                                                    },
                                                    [_vm._v("mdi-email")]
                                                  ),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        primary_contact.email
                                                      )
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-center",
                                      attrs: { cols: "2" }
                                    },
                                    [
                                      _c(
                                        "v-scale-transition",
                                        [
                                          active
                                            ? _c("v-icon", {
                                                attrs: {
                                                  color: "white",
                                                  size: "36"
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    "mdi-check-circle-outline"
                                                  )
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
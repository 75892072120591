<template>
    <v-sheet class="mx-auto mb-6" elevation="8" max-width="800">
        <v-slide-group
            v-model="model"
            class="pa-1"
            mandatory
            show-arrows
            center-active
        >
            <v-slide-item
                v-for="({ primary_contact }, index) in items"
                :key="index"
                v-slot="{ active, toggle }"
            >
                <v-card
                    :color="active ? 'accent lighten-2' : 'grey lighten-4'"
                    class="ma-2"
                    height="150"
                    width="330"
                    @click="toggle"
                >
                    <v-card-text>
                        <!--  align="center" justify="center" -->
                        <v-row
                            class="fill-height"
                            align="center"
                            justify="center"
                        >
                            <v-col cols="10" class="py-0 my-0">
                                <div
                                    class="d-flex"
                                    :class="{ 'white--text': active }"
                                >
                                    <v-icon
                                        class="mr-2"
                                        :class="{ 'white--text': active }"
                                        >mdi-map-marker</v-icon
                                    >
                                    <div class="d-flex flex-column">
                                        <h4>{{ primary_contact.name }}</h4>
                                        <span class="font-weight-regular">
                                            {{ primary_contact.address }}
                                        </span>
                                        <span class="font-weight-light"
                                            >{{ primary_contact.city }},
                                            {{ primary_contact.state }}</span
                                        >
                                        <v-divider
                                            class="my-1"
                                            :class="{
                                                white: active,
                                            }"
                                        ></v-divider>
                                        <div class="d-flex">
                                            <v-icon
                                                small
                                                class="mr-1"
                                                :class="{
                                                    'white--text': active,
                                                }"
                                                >mdi-phone</v-icon
                                            >
                                            <span>{{
                                                primary_contact.phone
                                            }}</span>
                                        </div>
                                        <div class="d-flex">
                                            <v-icon
                                                class="mr-1"
                                                :class="{
                                                    'white--text': active,
                                                }"
                                                >mdi-email</v-icon
                                            >
                                            <span>{{
                                                primary_contact.email
                                            }}</span>
                                        </div>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="2" class="text-center">
                                <v-scale-transition>
                                    <v-icon
                                        v-if="active"
                                        color="white"
                                        size="36"
                                        v-text="'mdi-check-circle-outline'"
                                    ></v-icon>
                                </v-scale-transition>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-slide-item>
        </v-slide-group>
    </v-sheet>
</template>


<script>
export default {
    name: "item-address-selector",
    components: {},
    props: {
        items: {
            type: Array,
            required: true,
        },
        selected: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    data() {
        return {
            model:
                this.selected > 0
                    ? this.items.findIndex((item) => item.id === this.selected)
                    : null,
        };
    },
    methods: {},
};
</script>

<style scoped>
</style>